/* Base styles for the floating button */
.floating-button {
  position: fixed;
  bottom: 70px;
  right: 5px;
  border-radius: 15px;
  background: transparent; /* Ensure the background is transparent */
  background-size: cover; /* Ensure image covers the button */
  background-position: center; /* Center the image */
  cursor: pointer;
  border: none; /* Remove any borders */
  outline: none; /* Remove outline to prevent default focus styles */
  transition: transform 0.3s ease;
}

/* Apply the zoom effect only to the collapsed state */
.floating-button:not(.expanded) {
  animation: zoom 2s infinite ease-in-out; /* Add zoom animation when collapsed */
}

/* Style for the image inside the button */
.floating-button img.contact-icon {
  width: 180px; /* Adjust size as needed */
  height: 80px;
  cursor: pointer; /* Show pointer cursor to indicate it’s clickable */
  transition: transform 0.3s ease; /* Smooth transition on hover */
}

/* Animation for zoom effect */
@keyframes zoom {
  0%, 100% {
    transform: scale(1); /* Normal size */
  }
  50% {
    transform: scale(1.1); /* Slightly larger size */
  }
}

/* Expanded form styles */
.floating-button.expanded {
  width: 300px;
  height: auto;
  border-radius: 10px;
  padding: 20px;
}

/* Contact form content */
.contact-content {
  background:  #ffffff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  color: #333;
}

.contact-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.contact-content h2 {
  font-size: 1.8rem;
  color: #104C97;
  margin-bottom: 10px;
}

.contact-content p {
  font-size: 1rem;
  color: #555;
  margin-bottom: 20px;
}

.contact-content form {
  width: 100%;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  font-size: 0.9rem;
  margin-bottom: 5px;
  color: #555;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 12px; /* Increased padding */
  font-size: 0.9rem;
  border: 1px solid #ccc; /* Slightly darker border */
  border-radius: 15px;
  box-sizing: border-box;
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #999a9b;
}

.form-group input:hover,
.form-group textarea:hover {
  border-color: #58595B; /* Change border color on hover */
}

.btn-submit {
  padding: 10px 15px;
  background-color: #104C97;
  color: #104C97;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-submit:hover {
  color: #ffffff;
  background: #104C97; /* Red background for visibility */
}

/* Close Button Styles */
.close-button {
  position: fixed;
  margin-top: 20px;
  margin-left: 335px;
  position: absolute;
  top: 20px;
  background: #104C97; /* Red background for visibility */
  color: #fff; /* White text */
  border-radius: 5px; /* Rounded corners */
  border: none; /* Ensure no border */
  font-size: 1.2rem;
  cursor: pointer;
}
.close-button:hover {
  background: #5b9bea; /* Darker red on hover */
  color: #fff; /* Ensure text remains white */
}

/* Media Queries for responsiveness */
@media only screen and (max-width: 600px) {
  .floating-button {
    width: 190px;
    height: 45px;
  }
  .floating-button.expanded {
    width: 300px;
    height: 535px;
  }
  .close-button {
    position: fixed;
    margin-top: 15px;
    margin-left: 240px;
    position: absolute;
    top: 20px;
  }
}

@media only screen and (min-width: 601px) and (max-width: 768px) {
  .floating-button {
    width: 190px;
    height: 45px;
  }
  .floating-button.expanded {
    width: 350px;
    height: 530px;
  }
  .close-button {
    position: fixed;
    margin-top: 15px;
    margin-left: 290px;
    position: absolute;
    top: 20px;
  }
}

@media only screen and (min-width: 769px) {
  .floating-button {
    width: 220px;
    height: 40px;
  }
  .floating-button.expanded {
    width: 380px;
    height: 535px;
  }
  .close-button {
    position: fixed;
    margin-top: 15px;
    margin-left: 320px;
    position: absolute;
    top: 20px;
  }
}
