/* Container Styles */
.container {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Card Styles */
.card {
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    background-color: #fff;
}

/* Form Header */
.card .text-center.h4 {
    font-size: 1.5rem;
    font-weight: bold;
    color: #007bff;
    margin-bottom: 20px;
    text-align: center;
}

/* Input Fields */
.form-control {
    font-size: 0.9rem;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    transition: border-color 0.3s ease;
    width: 100%;
    margin-bottom: 15px;
}

.form-control:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.25);
}

/* Button Styles */
.btn-primary {
    background-color: #007bff;
    border-color: #007bff;
    font-size: 1rem;
    padding: 10px 15px;
    border-radius: 4px;
    transition: background-color 0.3s ease, transform 0.2s ease;
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 200px;
}

.btn-primary:hover {
    background-color: #104C97;
    transform: scale(1.05);
}

.d-grid {
    margin-top: 20px;
    text-align: center;
}

/* Error Message */
.text-danger {
    font-size: 0.85rem;
    color: #dc3545;
    margin-top: 10px;
    text-align: center;
}

/* Product List */
.list-group-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.9rem;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 10px;
    background-color: #f9f9f9;
}

.list-group-item strong {
    color: #333;
}

.list-group-item:hover {
    background-color: #f1f1f1;
}
body {
    font-family: 'Abadi MT Std', sans-serif;
}
/* Responsive Styles */
@media (max-width: 768px) {
    .row {
        flex-direction: column;
    }

    .col-md-6 {
        width: 100%;
        margin-bottom: 20px;
    }

    .form-control {
        width: 100%;
    }

    .btn-primary {
        width: 100%;
        max-width: 100%;
    }
}
  