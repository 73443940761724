/* General Signup Styles */
.signup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f4f6f9;
  padding: 20px;
}

.signup-form {
  background-color: #58595B;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
  box-sizing: border-box;
}

h2 {
  text-align: center;
  color: #ffffff;
  margin-bottom: 20px;
}

.form-row {
  display: flex;
  gap: 15px;
  margin-bottom: 15px;
}

.form-group {
  flex: 1;
  display: flex;
  flex-direction: column;
}

label {
  font-weight: 600;
  margin-bottom: 5px;
  color: #ffffff;
}

input {
  padding: 10px;
  border: 1px solid #ccd2e0;
  border-radius: 5px;
  background-color: #f9fafc;
}

input:focus {
  border-color: #ffffff;
  outline: none;
}

.password-requirements {
  font-size: 0.9rem;
  background-color: #f9fafc;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccd2e0;
  border-radius: 5px;
  color: #6c757d;
}

.password-requirements ul {
  padding-left: 20px;
  margin: 0;
}

.error-message {
  color: red;
  text-align: center;
  margin-bottom: 10px;
}

.privacy-text {
  font-size: 0.9rem;
  color: #6c757d;
  text-align: center;
}

.btn-submit {
  width: 100%;
  padding: 12px;
  background-color: #ffffff;
  color:  #58595B;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}

.btn-submit:hover {
  background-color: #1a2b46;
}
