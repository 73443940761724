.hero-section .reviews-section {
  padding: 40px 20px;
  background-color: #ffffff; /* Light background for contrast */
  text-align: center;
}

.hero-section .reviews-section h3 {
  font-size: 28px;
  margin-bottom: 20px;
  color: #104C97;
}

.hero-section .reviews {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hero-section .review {
  margin: 15px 0;
  font-size: 18px;
  color: #333; /* Dark text for readability */
  padding: 0; /* Remove padding */
  background-color: transparent; /* No background */
  box-shadow: none; /* Remove shadow */
}

.hero-section .review p {
  margin: 5px 0;
}

/* Main Banner */
.banner-section {
  position: relative;
  width: 100%;
  height: 500px; /* Original height */
  overflow: hidden;
}

.banner-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.banner-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7);
}

/* Featured Section */
.featured-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
  background-color: #ffffff;
}

.section-heading {
  text-align: center;
  margin-bottom: 40px;
  width: 100%;
}

.section-heading h3 {
  color: #104C97;
  font-size: 2rem;
  font-weight: 600;
  margin: 0;
}

.featured-tiles-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
  width: 100%;
  max-width: 1200px;
  padding: 20px;
  margin: 0 auto;
}

.featured-tile {
  flex: 0 1 350px;
  background: white;
  border-radius: 12px;
  padding: 25px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
}
.featured-tile:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px #104C97;
}

.featured-tile h3 {
  color: #104C97;
  margin-bottom: 5px;
  font-size: 1.4rem;
}

.featured-tile p {
  color: #666;
  line-height: 1.6;
  font-size: 0.95rem;
}

@media (max-width: 1024px) {
  .featured-tiles-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Product Slider */
.product-slider {
  padding: 20px;
  background: linear-gradient(to right, #104C97, #ffffff); /* Gradient background */
  overflow: hidden;
  width: 100%;
  position: relative;
}
.product-slider h3 {
  text-align: center;
  color: white;
}
.slider-wrapper {
  width: 100%;
  overflow: hidden;
}

.slider-container {
  display: flex;
  animation: slide 30s linear infinite; /* Slower and seamless animation */
}

.slider-item {
  flex: 0 0 25%; /* 4 items per row */
  box-sizing: border-box;
  padding: 10px;
}

.slider-item img {
  width: 100%;
  height: 200px; /* Set a fixed height for uniform appearance */
  object-fit: contain; /* Prevent cropping by fitting the image within the container */
  border-radius: 5px;
  background-color: #fff; /* Optional: Add a background to ensure empty space looks neat */
}

/* Animation */
@keyframes slide {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}


.features {
  display: flex;
  flex-direction: row;
  padding: 2rem 0;
  background-color: #ffffff;
  justify-content: center; /* Center horizontally */
  align-items: center;
}

.feature p {
  margin-top: 40px;
  font-size: 1.8rem;
  color: #104C97;
  text-align: center;
  font-weight: bold ;
  background-color: #ffffff;
}

.tile-teaser {
  margin: 13px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #ffffff; /* Light background */
  border: 1px solid #ddd; /* Light border */
  border-radius: 8px; /* Rounded corners */
  padding: 16px; /* Padding around the content */
  margin: 10px; /* Space between tiles */
  width: 348px; /* Set a fixed width */
  height: 550px; /* Set a fixed height */
  box-sizing: border-box; /* Ensure padding is included in the height/width */
  transition: transform 0.3s; /* Smooth scaling effect */
  text-decoration: none;
}

.tile-teaser:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 20px #104C97;
}

.tile-image {
  width: 100%; /* Full width of the tile */
  height: 220px; /* Fixed height for the image */
  object-fit: cover; /* Maintain aspect ratio and fit within the container */
  border-bottom: 1px solid #ddd; /* Optional: Separate image visually */
}

.tile-title {
  font-weight: bold;
  margin: 1rem 0;
  font-size: 1.2rem;
  color: #58595B;
  text-align: center;
}

.tile-text {
  margin: 0 1rem 1rem;
  color: #555;
  font-size: 0.95rem;
  line-height: 1.4;
  text-align: center;
  overflow: hidden; /* Ensure text doesn't overflow */
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Limit to 3 lines */
  -webkit-box-orient: vertical;
    text-decoration: none;
}

.tile-link {
  margin-top: auto; /* Push link to the bottom of the tile */
  text-decoration: none;
}

.tile-link span {
  display: inline-block;
  color: #444c54;
  text-decoration: none;
  font-size: 0.95rem;
  transition: color 0.3s;
}

.tile-link span:hover {
  color: #444c54;
  text-decoration: none;
}

#main-teaser-container {
  height: 300px;
  align-items: center;
  background-color: #ffffff;
}
/* Main Container */
#reference-teaser-container {
  margin-top: 20px;
  height: 150px;
  background: linear-gradient(to right, #ffffff, #104C97); /* Gradient background */
  padding: 30px; /* Add spacing */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Content Card */
.reference-teaser-content {
  background-color: #fff; /* White background */
  border-radius: 12px; /* Rounded corners */
  box-shadow: 0 8px 12px #104C97; /* Subtle shadow */
  text-align: center; /* Center-align text */
  padding: 40px; /* Inner spacing */
  max-width: 700px; /* Restrict width */
}

/* Preheadline */
.teaser-preheadline {
  color: #6c757d; /* Muted gray text */
  font-size: 0.9rem;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-weight: 600;
}

/* Headline */
.teaser-headline {
  color: #104C97; /* Darker text */
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
}

/* Description */
.teaser-description {
  color: #495057; /* Subtle gray text */
  font-size: 1rem;
  margin-bottom: 30px;
  line-height: 1.5;
}

/* Button */
.teaser-button {
  background-color: #104C97; /* Teal button color */
  color: #fff; /* White text */
  padding: 12px 24px;
  font-size: 1rem;
  text-decoration: none;
  font-weight: 500;
  border-radius: 5px; /* Rounded corners */
  display: inline-block;
  transition: background-color 0.3s ease;
}

.teaser-button:hover {
  background-color: #365680; /* Darker teal on hover */
}

/* Mobile-Specific Styles: Force 2 Tiles Per Row Cleanly */
@media (max-width: 500px) {


    .features {
        display: flex;
        flex-wrap: wrap; /* Allow wrapping to the next row */
        padding: 2rem 0;
        background-color: #ffffff;
        justify-content: center; /* Center-align the tiles in the row */
        align-items: center;
    }

    .tile-teaser {
        flex: 0 0 calc(50% - 1rem); /* Each tile takes 50% of the row width, minus some margin */
        max-width: calc(50% - 1rem); /* Prevent tiles from exceeding the desired size */
        height: 300px;
        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: 8px;
        box-shadow: 0 2px 4px #5b80ad;
        overflow: hidden;
        text-align: center;
        padding: 10px;
        box-sizing: border-box;
        margin: 0.5rem; /* Add spacing between tiles */
    }


.featured-section {
  display: flex;
        flex-wrap: wrap; /* Enable wrapping */
        justify-content: space-between; /* Adjust alignment */
        width: 100%; /* Ensure full width */
        gap: 0; /* No gaps by default */
        box-sizing: border-box;
}
  .featured-tile {
    flex: 0 0 calc(50% - 10px); /* Subtract margin for spacing */
        margin: 5px; /* Equal spacing around tiles */
        padding: 10px; /* Inner padding */
        box-sizing: border-box; /* Include padding/borders in width */
        height: 300px; /* Fixed height for tiles */
        margin: 0;
}


.featured-tile h3 {
margin: 0%;
  font-size: 1rem;

}

.featured-tile p {
  font-size: 0.85rem;
  margin-top: 5px;
}
  /* Tile Container: Use Grid for 2 Columns */
  .tile-container {
    display: grid; /* Use grid layout */
    grid-template-columns: repeat(2, 1fr); /* 2 equal-width tiles per row */
    gap: 15px; /* Uniform spacing between tiles */
    padding: 10px; /* Compact padding */
    margin: 0; /* Reset any margins */
  }
  /* Image Styling */
  .tile-image {
    width: 100%; /* Full width */
    height: 120px; /* Consistent height */
    object-fit: contain; /* Prevent distortion and keep proportions */
    margin-bottom: 8px; /* Spacing below image */
  }

  /* Title Styling */
  .tile-title {
    font-size: 0.9rem; /* Smaller font size */
    font-weight: bold; /* Make it bold */
    margin: 5px 0; /* Compact spacing */
    color: #ffffff; /* Primary brand color */
  }

  /* Text Styling */
  .tile-text {
    font-size: 0.8rem; /* Smaller font size */
    color: #555; /* Subtle gray text */
    margin: 0; /* No extra margins */
  }


  .product-slider {
    padding-top: 5px;
    padding-bottom: 5px;
    background: linear-gradient(to right, #104C97, #ffffff);
    overflow: hidden;
    width: 100%;
    position: relative;
}
  .slider-wrapper {
    width: 100%;
    overflow: hidden;
  }
  
  .slider-container {
    display: flex;
    animation: slide 30s linear infinite; /* Slower and seamless animation */
  }
  
  .slider-item {
    flex: 0 0 45%; /* 4 items per row */
  }
  
  .slider-item img {
    width: 100%;
    height: 100px; /* Set a fixed height for uniform appearance */
  }
  
  #main-teaser-container {
    padding: 1px;
    height: 300px;
    align-items: center;
    background-color: #ffffff;
  }
  /* Main Container */
  #reference-teaser-container {
    margin-top: 100px;
    height: 70px;
    padding: 30px; /* Add spacing */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Content Card */
  .reference-teaser-content {
    width: 250px;
    height: 210px;
    border-radius: 15px; /* Rounded corners */
    text-align: center; /* Center-align text */
    padding: 20px; /* Inner spacing */
  }

  .teaser-headline {
    font-size: 1.25rem;
    margin-bottom: 15px;
}

.teaser-preheadline {
  font-size: 0.85rem;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 7px;
  font-weight: 550;
}

.teaser-description {
  color: #495057;
  font-size: .75rem;
  margin-bottom: 20px;
  line-height: 1.5;
}

.teaser-button {
  background-color: #104C97;
  color: #fff;
  padding: 7px 10px;
  font-size: 1rem;
  text-decoration: none;
  font-weight: 100;
  border-radius: 5px;
  display: inline-block;
  margin-top: -10px;
  transition: background-color 0.3s ease;
}
  /* Ensure Consistent View */
  body, html {
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Prevent horizontal scrolling */
  }
  .feature p {
    margin-top: 40px;
    font-size: 1.25rem;
    color: #104C97;

}
.banner-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.banner-section {
  position: relative;
  width: 100%;
  height: 360px; /* Original height */
  overflow: hidden;
}

.section-heading h3 {
  font-size: 1.25rem; /* Smaller font size for mobile */

}
  .hero-section .reviews-section {
      padding: 20px; /* Adjust padding for mobile */
  }

  .hero-section .reviews-section h3 {
      font-size: 1.25rem; /* Smaller font size for mobile */
  }

  .hero-section .review {
      font-size: 16px; /* Smaller font size for reviews */
  }
  .banner-text {
      font-size: 15px; /* Smaller font size for banner text */
      width: 350px;
  }

  .featured-tiles-container {
    grid-template-columns: 1fr;
    gap: 20px;
    padding: 0 10px;
  }
}