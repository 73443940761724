.products-page {
  display: flex;
}

.sidebar {
  width: 20%;
  background: #ffffff;
  padding: 10px;
}

.sidebar h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.sidebar ul {
  list-style: none;
  padding: 0;
}

.sidebar li {
  margin: 10px 0;
}

.sidebar button {
  width: 100%;
  background: none;
  border: none;
  font-size: 16px;
  text-align: left;
  cursor: pointer;
  padding: 5px 10px;
  color: #333;
}

.sidebar button:hover {
  background: #ddd;
}

.subcategory-list {
  margin-left: 10px;
  padding-left: 10px;
  border-left: 2px solid #ddd;
  font-size: 14px;
  color: #666;
}

.subcategory-list li {
  margin: 5px 0;
}

.content {
  flex: 1;
  padding: 20px;
}

.categories-tiles {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 tiles per row */
  gap: 20px; /* Spacing between tiles */
  margin-top: 20px;
}

.category-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: #fff;
  border-radius: 8px;
  padding: 15px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.category-tile:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.category-tile img {
  width: 200px; /* Fixed width */
  height: 200px; /* Fixed height */
  object-fit: cover; /* Ensures the image fills the area without distortion */
  border-radius: 8px;
  margin-bottom: 10px;
}

.category-tile h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0;
}

.category-tile p {
  font-size: 14px;
  color: #555;
}
