/* Ensure the body and html take up the full height */
html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
}

/* The .App class should fill the entire height */
.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Ensures the content takes full viewport height */
}

/* Main content area to expand and take available space */
.App > main {
    flex-grow: 1; /* Ensures the main content grows and footer stays at the bottom */
}

/* Footer that sticks to the bottom of the page */
footer {
    margin-top: auto; /* Pushes the footer to the bottom */
}

/* Ensure there is no horizontal overflow issue */
body {
    overflow-x: hidden;
}

/* Section default styles */
section {
    padding: 0;
    margin: 0;
}

/* Mobile (up to 600px) */
@media only screen and (max-width: 600px) {
    .container {
        width: 90%; /* Adjust container width for mobile */
    }

    body {
        font-size: 14px; /* Smaller font size for mobile */
    }
}

/* iPad / Tablet (601px to 768px) */
@media only screen and (min-width: 601px) and (max-width: 768px) {
    .container {
        width: 85%; /* Adjust container width for tablets */
    }

    body {
        font-size: 15px; /* Medium font size for tablets */
    }
}

/* Laptop (769px to 1024px) */
@media only screen and (min-width: 769px) and (max-width: 1024px) {
    .container {
        width: 80%; /* Default container width for laptops */
    }

    body {
        font-size: 16px; /* Default font size for laptops */
    }
}

/* Desktop (1025px and above) */
@media only screen and (min-width: 1025px) {
    .container {
        width: 70%; /* Larger container width for desktops */
    }

    body {
        font-size: 18px; /* Larger font size for desktop */
    }
}
