/* General Login Styles */

/* Media Queries for responsiveness */
@media (max-width: 768px) {
    .login-container {
        padding: 20px; /* Adjust padding for mobile */
    }

    .login-form {
        width: 90%; /* Make form width responsive */
        margin: auto; /* Center the form */
    }

    h2 {
        font-size: 1.5rem; /* Smaller font size for mobile */
    }

    button[type="submit"] {
        padding: 10px; /* Adjust button padding for mobile */
    }
}


.login-container {
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f4f6f9;
  padding: 10px;
  box-sizing: border-box;
}

.login-form {
  background-color: #58595B;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: center;
}

h2 {
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 20px;
  color: #ffffff;
}

input[type="text"],
input[type="password"] {
  width: 100%;
  padding: 12px 15px;
  margin-bottom: 15px;
  border: 1px solid #ccd2e0;
  border-radius: 5px;
  background-color: #f9fafc;
  box-sizing: border-box;
}

input[type="checkbox"] {
  margin-right: 8px;
}

/* Checkbox Container */
.checkbox-container {
  display: flex;
  align-items: center; /* Vertically align the checkbox and label */
  justify-content: flex-start; /* Align to the left */
  margin-bottom: 20px; /* Space below */
}

.checkbox-container input[type="checkbox"] {
  margin-right: 10px; /* Space between checkbox and label */
  width: 16px;
  height: 16px;
}

.checkbox-container label {
  font-size: 1rem;
  color: #ffffff;
  font-weight: 500;
}

button[type="submit"] {
  width: 100%;
  padding: 12px;
  background-color: #104C97;
  color: #f9fbff;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button[type="submit"]:hover {
  background: #4d8fe1; /* Red background for visibility */
}

.unlock-link,
.signup-text {
  margin-top: 10px;
  font-size: 0.9rem;
  color: #ffffff;
}

.signup-link {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

.signup-link:hover {
  text-decoration: underline;
}


.footer-links {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-links li {
  margin: 5px 0;
}

.footer-links a {
  color: #ffffff;
  text-decoration: none;
}

.footer-links a:hover {
  text-decoration: underline;
}
