.header {
  background:#ffffff ; /* Background color */
  height: auto; /* Fixed header height */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  position: sticky;
  top: 0;
  z-index: 1000;
  flex-wrap: wrap;
  overflow: visible; /* Allow dropdown overflow */
}

.logo {
  margin-right: auto;
  flex-grow: 0;
  height: 60px;
}

.nav-links {
  list-style: none;
  display: flex;
}

.nav-links li {
  margin-left: 20px;
}

.nav-links li a {
  color:  #104C97;
  text-decoration: none;
}

/* Search Form Styling */
.search-form {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  margin-top: 11px;
}

.search-form input {
  flex: 8;
  border: none;
  background: transparent;
  color: #104C97;
  font-size: 1rem;
  padding: 8px 0;
  outline: none;
}

.search-form input::placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.search-form button {
  flex: 0.5;
  font-size: 1.5rem;
  display: flex;
  margin-bottom: 15px;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
  position: relative;
}

.search-form button:hover {
  transform: scale(1.2);
  color: #fff;
}

/* Suggestions Dropdown */
.suggestions-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 70%;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(200, 200, 200, 0.5);
  border-radius: 5px;
  z-index: 1000;
  max-height: 250px;
  overflow-y: auto; /* Enable vertical scroll */
  overflow-x: hidden; /* Hide horizontal scroll */
}

.suggestion-item {
  display: flex;
  color: #104C97;
  padding: 5px;
  cursor: pointer;
}

.suggestion-item:hover {
  background-color: rgba(240, 240, 240, 0.9);
  transform: scale(1.02);
}

/* Scrollbar Styling */
.suggestions-dropdown::-webkit-scrollbar {
  width: 8px;
}

.suggestions-dropdown::-webkit-scrollbar-thumb {
  background-color: rgba(4, 4, 4, 0.2);
  border-radius: 4px;
}

/* Hamburger Menu */
.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.hamburger .bar {
  background-color:  #104C97;
  height: 4px;
  width: 30px;
  margin: 3px 0;
  transition: all 0.3s ease;
  margin-left: 15px;
}

.hamburger.open .bar:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.hamburger.open .bar:nth-child(2) {
  opacity: 0;
}

.hamburger.open .bar:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}

/* Navigation Links */
.nav-links {
  list-style: none;
  display: flex;
  gap: 20px;
}

.nav-links li a {
  color:  #104C97;
  text-decoration: none;
}

/* Hamburger Menu */
.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

/* Responsive Styling */
@media only screen and (max-width: 1400px) {
  .header {
    flex-direction: row;
    position: relative;
  }

  .nav-links {
    display: none;
    flex-direction: column;
    background-color: #ffffff;
    width: 100%;
    position: absolute;
    top: 70%; /* Below the header */
    left: 0;
    padding: 20px 0;
  }

  .nav-links.active {
    display: flex;
  }

  .nav-links li {
    margin: 10px 0;
    text-align: center;
  }

  .hamburger {
    display: flex;
    margin-bottom: 12px;
  }

  /* Hamburger Animation */
  .hamburger.open .bar:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
  }

  .hamburger.open .bar:nth-child(2) {
    opacity: 0;
  }

  .hamburger.open .bar:nth-child(3) {
    transform: rotate(-45deg) translate(5px, -5px);
  }
}
