.product-detail-page {
  display: flex;
  gap: 30px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9; /* Light background */
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
  .product-detail-page {
      flex-direction: column;
      padding: 15px;
  }

  .product-image-container {
      max-width: 100%;
  }

  .product-details {
      text-align: center;
  }

  .product-details h1 {
      font-size: 1.4rem;
  }

  .product-details p {
      font-size: 1rem;
  }

  .get-quote-button {
      width: 100%;
      margin-top: 15px;
  }
}

.product-image-container {
  max-width: 400px;
  flex: 1;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.product-large-image {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease;
}

.product-large-image:hover {
  transform: scale(1.05); /* Subtle zoom on hover */
}

.product-details {
  flex: 1;
  text-align: left;
}

.product-details h1 {
  font-size: 2rem;
  margin-bottom: 15px;
  color: #333;
  font-family: 'Roboto', sans-serif;
}

.product-details p {
  font-size: 1.1rem;
  margin: 8px 0;
  color: #555;
}

.get-quote-button {
  margin-top: 20px;
  padding: 12px 25px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1.1rem;
  font-family: 'Roboto', sans-serif;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.get-quote-button:hover {
  background-color: #0056b3;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}
