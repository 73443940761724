/* General Footer Styling */
.footer {
    background-color: #ffffff; /* Dark background for footer */
    color: #104C97; /* Light gray color for text */
    padding: 40px 20px;
    margin-bottom: 0; /* Remove bottom margin */
}

.social-icons-footer {
  display: flex;
  justify-content: center;
  gap: 65px;
  margin-top: 20px;
}

.social-icons-footer a {
  color: #104C97; /* Match your theme color for footer icons */
  font-size: 30px;
  text-decoration: none;
  transition: color 0.3s ease;
}

.social-icons-footer a:hover {
  color: #104C97; /* Darker shade on hover */
}
  
  .footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .footer-section {
    flex: 1 1 200px;
    margin: 10px;
  }
  
  .footer-section h4 {
    color: #104C97; /* White color for section titles */
    margin-bottom: 15px;
    font-size: 1.2rem;
  }
  
  .footer-section ul {
    list-style-type: none;
    padding: 0;
  }
  
  .footer-section ul li {
    margin: 5px 0;
    color: #104C97; /* Light gray color for list items */
    font-size: 1rem;
  }
  
  .footer-section ul li a {
    color: #104C97; /* Same light gray for links */
    text-decoration: none;
  }
  
  .footer-section ul li a:hover {
    color: #104C97; /* White color on hover */
    text-decoration: underline;
  }
  
  /* Social Media Icons */
  .social-icons {
    display: flex;
    justify-content: center;
    gap: 65px;
    margin-top: 20px;
  }
  
  .social-icons a {
    color: #104C97; /* Match your theme color */
    font-size: 30px;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .social-icons a:hover {
    color: #104C97; /* Darker shade on hover */
  }
  
  /* Footer Text */
  .footer-bottom {
    text-align: center; /* Center align the footer text */
  }

  .footer-bottom p {
    font-size: 14px;
    color: #104C97;
    margin-top: 0; /* Remove top margin to reduce gap */
    padding-top: 0; /* Ensure no padding */
  }
  
  .footer-section p {
    color: #104C97; /* White color for text */
    font-size: 1rem; /* Ensure readability */
    line-height: 1.5; /* Add line spacing */
    margin-bottom: 10px; /* Add space between each paragraph */
  }
  
  .footer-section p a {
    color: #104C97; /* White color for links */
    text-decoration: none; /* No underline */
  }
  
  .footer-section p a:hover {
    color: #104C97; /* White color on hover */
    text-decoration: underline; /* Underline on hover */
  }
  
  /* Mobile (up to 600px) */
  @media only screen and (max-width: 600px) {
    .footer {
      padding: 15px;
    }
  
    .footer p {
      font-size: 0.9rem;
    }
  
    .social-icons a {
      font-size: 1.2em;
    }
  }
  
  /* iPad / Tablet (601px to 768px) */
  @media only screen and (min-width: 601px) and (max-width: 768px) {
    .footer {
      padding: 18px;
    }
  
    .footer p {
      font-size: 1rem;
    }
  }
  
  /* Laptop (769px to 1024px) */
  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    .footer {
      padding: 20px;
    }
  
    .footer p {
      font-size: 1rem;
    }
  }
  
  /* Desktop (1025px and above) */
  @media only screen and (min-width: 1025px) {
    .footer {
      padding: 25px;
    }
  
    .footer p {
      font-size: 1.1rem;
    }
  }
  