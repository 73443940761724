/* General Page Styling */
.brand-page {
  padding: 20px;
  text-align: center; /* Center align the heading */
}

.brand-page h1 {
  margin-bottom: 20px; /* Add some space below heading */
}

/* Content Layout */
.brand-content {
  display: flex;
  gap: 10px; /* Decreased gap between left and right sections */
  align-items: flex-start;
  border-top: 1px solid #ffffff; /* Top border for overall section */
  padding-top: 10px;
}

.brand-image-container {
  text-align: center;
  margin-bottom: 20px; /* Space below the image */
}

.brand-image {
  width: 50px;
  height: 100px;
  object-fit: contain; /* Maintain aspect ratio without distortion */
}

/* Left Sidebar: Product Names */
.product-names-sidebar {
  flex: 1;
  padding: 10px;
  background-color: transparent;
  border-right: 2px solid #58595B; /* Divider line */
  text-align: left; /* Align list items to the left */
}

.product-names-sidebar h3 {
  margin-bottom: 10px;
  color: #58595B;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center; /* Center align the sidebar title */
}

.product-names-sidebar ul {
  list-style: none; /* Remove bullets */
  padding: 0;
  margin: 0;
}

.product-name-item {
  cursor: pointer;
  color: #58595B; /* Default text color */
  font-size: 1rem;
  font-weight: 500;
  margin: 5px 0; /* Spacing between names */
  padding: 5px 0; /* Vertical spacing */
  transition: color 0.3s ease, font-weight 0.3s ease;
}

.product-name-item:hover,
.product-name-item.selected {
  color: #104C97; /* Hover color */
  font-weight: bold; /* Bold on hover */
  text-decoration: underline; /* Underline for emphasis */
}

/* Right Side: Product List */
.products-list-container {
  flex: 4; /* Increased the space for product list */
  padding-left: 10px;
}

.products-list {
  display: grid; /* Use grid for list layout */
  grid-template-columns: repeat(3, 1fr); /* Default: 3 equal columns */
  gap: 20px; /* Space between grid items */
  list-style: none;
  padding: 0;
  margin: 0;
}

.product-list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  padding: 10px;
  background: none;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.product-list-item:hover {
  background-color: #f8f8f8; /* Light gray background on hover */
}

.list-image {
  width: 60px; /* Fixed image size */
  height: 60px;
  object-fit: contain;
  margin-right: 10px;
}

.product-info {
  flex: 1;
  text-align: left;
}

.product-info h3 {
  margin: 0;
  font-size: 1rem;
  color: #58595B;
}

.product-info p {
  margin: 5px 0;
  font-size: 0.9rem;
  color: #555; /* Lighter gray for details */
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .brand-content {
    flex-direction: column; /* Stack sidebar and list vertically */
  }

  .product-names-sidebar {
    border-right: none;
    border-bottom: 2px solid #58595B;
    margin-bottom: 10px;
  }
  .products-list {
    grid-template-columns: 1fr; /* Single column layout */
    gap: 10px;
  }

  .product-list-item {
    flex-direction: column; /* Stack image and text vertically */
    align-items: center;
    text-align: center;
  }

  .list-image {
    width: 50px;
    height: 50px;
    margin-bottom: 5px;
  }


}


@media (max-width: 760px) {

  .brands-grid {
    gap: 10px; /* Reduce gap for compact layout */
  }
  .brand-tile {
    max-width: 240px; /* Further reduce tile width */
    padding: 6px; /* Compact padding */
  }
  .brand-content {
    flex-direction: row;
}
.product-names-sidebar {
  border-right: 2px solid #58595B;
  margin-right: 10px;
}
}