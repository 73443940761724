/* General Products Section */
.products-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 20px;
  text-align: center;
}

.products-section h1 {
  font-size: 2rem;
  color: #104C97;
  margin-bottom: 20px;
}

/* Brands Grid */
.brands-grid {
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); /* Dynamic grid for responsive design */
  justify-items: center;
}

/* Brand Tile */
.brand-tile {
  background: #ffffff;
  border: 1px solid #ddd;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 120px; /* Smaller fixed height */
  width: 180px; /* Smaller fixed width */
  max-width: 100%;
  box-sizing: border-box;
  position: relative;
  justify-content: center;
  align-items: center;
}

.brand-tile img {
  align-items: center;
  width: 80%; /* Ensure the image fits within the container */
  height: auto; /* Maintain aspect ratio */
  max-height: 70%; /* Limit height for compact tiles */
  object-fit: contain; /* Prevent cropping */
  margin-bottom: 8px; /* Space between image and text */
}

.brands-name {
  height: auto; /* Dynamic height for text */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
  text-align: center;
}

.brands-name h3 {
  font-size: 0.9rem; /* Smaller font size */
  color: #104C97;
  margin: 0;
  white-space: nowrap; /* Prevent text wrapping */
  overflow: hidden; /* Hide overflowing text */
  text-overflow: ellipsis; /* Add ellipsis for long text */
}

.brand-tile:hover {
  transform: translateY(-3px);
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
}

/* Mobile (up to 600px) */
@media only screen and (max-width: 600px) {
  .products-section h1 {
    font-size: 1.5rem;
  }

  .brands-grid {
    grid-template-columns: repeat(2, 1fr); /* Two tiles per row on small screens */
    gap: 10px; /* Smaller gap */
  }

  .brand-tile {
    height: 140px; /* Reduced height */
    width: 140px; /* Reduced width */
  }

  .brand-tile img {
    max-height: 60%;
  }

  .brands-name h3 {
    font-size: 0.8rem;
  }
}

/* Tablet (601px to 768px) */
@media only screen and (min-width: 601px) and (max-width: 768px) {
  .products-section h1 {
    font-size: 1.8rem;
  }

  .brands-grid {
    grid-template-columns: repeat(3, 1fr); /* Three tiles per row */
  }

  .brand-tile {
    height: 160px;
    width: 160px;
  }

  .brand-tile img {
    max-height: 65%;
  }

  .brands-name h3 {
    font-size: 0.9rem;
  }
}

/* Laptop (769px to 1024px) */
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .products-section h1 {
    font-size: 2rem;
  }

  .brands-grid {
    grid-template-columns: repeat(4, 1fr); /* Four tiles per row */
  }

}

/* Desktop (1025px and above) */
@media only screen and (min-width: 1025px) {
  .products-section h1 {
    font-size: 2.5rem;
  }

  .brands-grid {
    grid-template-columns: repeat(7, 1fr); /* Five tiles per row */
  }

}
