.subcategories-page {
    padding: 20px;
  }
  
  .subcategories-page h1 {
    margin-bottom: 20px;
    text-align: center;
    font-size: 28px;
    color: #333;
  }
  
  .subcategories-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr); /* Ensures 3 tiles per row */
    gap: 30px; /* Adds spacing between tiles */
    margin-top: 20px;
  }
  
  .subcategory-tile {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border-radius: 8px;
    padding: 20px; /* Larger padding for a better look */
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .subcategory-tile:hover {
    transform: scale(1.1);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
  
  .subcategory-tile img {
    width: 150px; /* Larger image for better visibility */
    height: 150px;
    object-fit: cover; /* Ensures image fills the tile */
    border-radius: 8px;
    margin-bottom: 15px;
  }
  
  .subcategory-tile h3 {
    font-size: 18px;
    font-weight: bold;
    margin: 10px 0 5px 0;
    color: #333;
  }
  
  .subcategory-tile p {
    font-size: 14px;
    color: #555;
  }
  