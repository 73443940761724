
body {
  font-family: 'Abadi MT Std', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #ffffff;
}

h1 {
  text-align: center;
  margin: 20px 0;
}

.products-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  padding: 20px;
}

.product-card {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.product-card img {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
}

body {
  margin: 0;
  font-family: 'Abadi MT Std', sans-serif;
}

header, footer {
  background-color: #333;
  color: white;
}

nav a {
  text-decoration: none;
}

nav a:hover {
  text-decoration: underline;
}

main {
  margin: 0 auto;
  max-width: 800px;
}
